import { render, staticRenderFns } from "./AddPhrase.vue?vue&type=template&id=6b3de961&scoped=true"
import script from "./AddPhrase.vue?vue&type=script&lang=js"
export * from "./AddPhrase.vue?vue&type=script&lang=js"
import style0 from "./AddPhrase.vue?vue&type=style&index=0&id=6b3de961&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3de961",
  null
  
)

export default component.exports
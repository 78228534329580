<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addPhrase" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
                name="phrase"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Фраза</label>
                  <md-input
                    v-model="phrase"
                    md-counter="20"
                    @focus="focused = true"
                    @blur="focused = false"
                  />
                  <span v-if="focused" class="md-helper-text">
                    Фраза не может быть длиннее 20 символов и содержать буквы и
                    символы
                  </span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/fast-messages/phrases/list/')"
              >
                Отменить
              </md-button>
              <md-button class="md-raised md-success" :disabled="!isValid">
                Создать
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phrase: "",
      focused: false,
    };
  },
  computed: {
    isValid() {
      return this.phrase.length <= 20;
    },
  },
  watch: {
    phrase(value) {
      if (value.length > 20) {
        this.$refs.addPhrase.setErrors({
          phrase: "Проверьте введенную фразу",
        });
      } else {
        this.$refs.addPhrase.setErrors({
          phrase: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
::v-deep .validation-error {
  right: 40px;
  bottom: -3px;
  &__text {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
